import { Link, useFetcher, useNavigate } from "@remix-run/react";
import { FaArrowLeft, FaX } from "react-icons/fa6";
import { useState } from "react";
import { useEffect } from "react";

export default function RegisterModal({
  registerErrorMessage,
  onSubmit,
}: {
  onSubmit: (data: any) => void;
  registerErrorMessage: string;
}) {
  const [showSecondPage, setShowSecondPage] = useState(false);
  const [usernameError, setUsernameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [genderError, setGenderError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [gender, setGender] = useState("");
  const [year, setYear] = useState(0);
  const [month, setMonth] = useState(0);
  const [day, setDay] = useState(0);
  const [yearError, setYearError] = useState("");
  const [monthError, setMonthError] = useState("");
  const [dayError, setDayError] = useState("");
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleLinkClick = () => {
    useEffect(() => {
      const modal = document.getElementById("registermodal");
      modal?.classList.remove("show");
      document.querySelectorAll(".modal-backdrop").forEach((backdrop) => backdrop.remove());
    });
  };

  const generateYearOptions = () => {
    const arr = [];

    const startYear = 1922;
    const endYear = new Date().getFullYear() - 18;

    for (let i = endYear; i >= startYear; i--) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  };

  const generateDateOptions = () => {
    const arr = [];

    const startYear = 1;
    const endYear = 31;

    for (let i = startYear; i <= endYear; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  };

  const generateMonthOptions = () => {
    const arr = [];

    const startYear = 1;
    const endYear = 12;

    for (let i = startYear; i <= endYear; i++) {
      arr.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return arr;
  };

  function gotoSecondPage() {
    let hasError = false;

    if (!username) {
      setUsernameError("Username is required");
      hasError = true;
    } else {
      if (username.length < 3) {
        setUsernameError("Username must be at least 3 characters");
        hasError = true;
      } else if (username.length > 50) {
        setUsernameError("Username must be at most 20 characters");
        hasError = true;
      } else {
        setUsernameError("");
      }
    }

    if (!password) {
      setPasswordError("Password is required");
      hasError = true;
    } else {
      if (password.length < 6) {
        setPasswordError("Password must be at least 6 characters");
        hasError = true;
      } else if (password.length > 200) {
        setPasswordError("Password must be at most 200 characters");
        hasError = true;
      } else {
        setPasswordError("");
      }
    }

    if (!gender) {
      setGenderError("Gender is required");
      hasError = true;
    }

    if (!hasError) {
      setShowSecondPage(true);
    }
  }

  function handleRegister(e: any) {
    e.preventDefault();
    let hasError = false;

    if (!year) {
      setYearError("Year is required");
      hasError = true;
    } else {
      setYearError("");
    }

    if (!month) {
      setMonthError("Month is required");
      hasError = true;
    } else {
      setMonthError("");
    }

    if (!day) {
      setDayError("Day is required");
      hasError = true;
    } else {
      setDayError("");
    }

    if (!email) {
      setEmailError("Email is required");
      hasError = true;
    } else {
      if (!/\S+@\S+\.\S+/.test(email)) {
        setEmailError("Invalid email address");
        hasError = true;
      } else {
        setEmailError("");
      }
    }

    // Birthdate need to add leading 0
    if (!hasError) {
      const zeroPad = (num: number) => String(num).padStart(2, "0");

      onSubmit({
        username,
        password,
        email,
        gender,
        birth_date: `${year}-${zeroPad(month)}-${zeroPad(day)}`,
      });
    }
  }

  return (
    <div className="modal fade" tabIndex={-1} id="registermodal">
      <div className="modal-dialog">
        <div className="modal-content">
          <div
            className="btn btn-icon btn-sm btn-active-light-primary ms-2 postionright"
            data-bs-dismiss="modal"
            data-backdrop="false"
            onClick={() => {}}
            aria-label="Close"
          >
            <FaX />
          </div>
          {showSecondPage && (
            <div className="top-left-arrow">
              <FaArrowLeft onClick={() => setShowSecondPage(false)} />
            </div>
          )}
          <div className="modal-body">
            {showSecondPage ? (
              <div className="feature_box_img">
                <img alt="chatter_icon" src="/register1.jpg" />
              </div>
            ) : (
              <div className="feature_box_img">
                <img alt="chatter_icon" src="/register2.jpg" />
              </div>
            )}

            <div className="main_cover_wrap paddingLeftRight-55px">
              <div className="TitlePopup font-weight-400">
                Create a free profile in 30 seconds to chat with our members
              </div>

              <form className="form" noValidate>
                {showSecondPage ? (
                  <div className="popup_form_wrapper step2">
                    <div className="InputForm">
                      <div className="mb-10 inputdesign">
                        <label>Your date of birth</label>
                        <div className="row">
                          <div className="col-lg-4">
                            <div>
                              <select
                                className="form-select withArrow"
                                aria-label="Select example"
                                value={year}
                                onChange={(e) =>
                                  setYear(parseInt(e.target.value))
                                }
                              >
                                <option value="">YYYY</option>
                                {generateYearOptions()}
                              </select>
                            </div>
                            {yearError && (
                              <div className="fv-plugins-message-container">
                                <span
                                  role="alert"
                                  style={{ color: "#E25771", fontSize: "14px" }}
                                >
                                  {yearError}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="col-lg-4">
                            <div>
                              <select
                                className="form-select withArrow"
                                aria-label="Select example"
                                value={month}
                                onChange={(e) =>
                                  setMonth(parseInt(e.target.value))
                                }
                              >
                                <option value="">MM</option>
                                {generateMonthOptions()}
                              </select>
                            </div>
                            {monthError && (
                              <div className="fv-plugins-message-container">
                                <span
                                  role="alert"
                                  style={{ color: "#E25771", fontSize: "14px" }}
                                >
                                  {monthError}
                                </span>
                              </div>
                            )}
                          </div>

                          <div className="col-lg-4">
                            <div>
                              <select
                                className="form-select"
                                aria-label="Select example"
                                value={day}
                                onChange={(e) =>
                                  setDay(parseInt(e.target.value))
                                }
                              >
                                <option value="">DD</option>
                                {generateDateOptions()}
                              </select>
                            </div>
                            {dayError && (
                              <div className="fv-plugins-message-container">
                                <span
                                  role="alert"
                                  style={{ color: "#E25771", fontSize: "14px" }}
                                >
                                  {dayError}
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="mb-10 inputdesign">
                        <input
                          className="form-control form-control-solid is-invalid"
                          type="email"
                          placeholder="Your Email Address"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {emailError && (
                          <div className="fv-plugins-message-container">
                            <span
                              role="alert"
                              style={{ color: "#E25771", fontSize: "14px" }}
                            >
                              {emailError}
                            </span>
                          </div>
                        )}
                      </div>

                      {registerErrorMessage && (
                        <div className="alert alert-danger mb-8">
                          {registerErrorMessage}
                        </div>
                      )}

                      <div
                        className="termscontion"
                        style={{ color: "rgba(0, 0, 0, 0.75)" }}
                      >
                        <div className="form-check form-check-custom form-check-solid">
                          <input
                            className="form-check-input is-valid"
                            style={{ height: "1.4rem", width: "1.4rem" }}
                            type="checkbox"
                            value={termsAccepted ? "1" : "0"}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                          />
                          By becoming a member, I agree to the{" "}
                          <Link to={"/terms-conditions"} style={{ color: "#E25771" }}
                            onClick={handleLinkClick}>
                            Terms and Conditions
                          </Link>
                          {/* and the use of fictional
                              entertainers’ profiles, marked with a heart icon, with which physical
                              contact is not possible. */}
                        </div>

                        <div className="mt-5">
                          <label>
                            By submitting my account information, I understand
                            that PWR Network Limited will process my personal
                            data in accordance with the{" "}
                            <Link to={"/privacy-policy"} style={{ color: "#E25771" }}
                              onClick={handleLinkClick}>
                              Privacy Statement
                            </Link>
                            .
                          </label>
                        </div>

                        {false && (
                          <div className="mb-lg-15 alert alert-danger">
                            <div className="alert-text font-weight-bold">
                              Post Op Message
                            </div>
                            {false && (
                              <Link to={"/auth/forgot-password"}>
                                Forgot Password?
                              </Link>
                            )}
                          </div>
                        )}

                        <div className="d-flex justify-content-center gap-5">
                          <button
                            disabled={!termsAccepted}
                            onClick={handleRegister}
                            className="btn btn-lg w-50 mt-5 h-55px white-pink-btn"
                            data-bs-dismiss="modal"
                            data-bs-target="#registermodal"
                          >
                            {!false && (
                              <span className="indicator-label">Chat Now</span>
                            )}
                            {false && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="popup_form_wrapper step1">
                    <div className="InputForm">
                      <div className="mb-10 inputdesign">
                        <input
                          type="text"
                          className="form-control form-control-solid is-valid"
                          name="username"
                          placeholder="Enter a username"
                          value={username}
                          onChange={(e) => setUsername(e.target.value)}
                        />
                        {usernameError && (
                          <div className="fv-plugins-message-container">
                            <span
                              role="alert"
                              style={{ color: "#E25771", fontSize: "14px" }}
                            >
                              {usernameError}
                            </span>
                          </div>
                        )}
                        <label>
                          <strong>Tip:</strong> Doesn't have to be your own
                          name.
                        </label>
                      </div>

                      <div className="mb-10 inputdesign">
                        <input
                          type="password"
                          className="form-control form-control-solid is-invalid"
                          name="password"
                          placeholder="Enter a secure password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                        {passwordError && (
                          <div className="fv-plugins-message-container-2">
                            <span
                              role="alert"
                              style={{ color: "#E25771", fontSize: "14px" }}
                            >
                              {passwordError}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="mb-10 inputdesign">
                        <select
                          id="gender"
                          className="form-control form-control-solid is-valid"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option disabled value="">
                            Select your gender
                          </option>
                          <option value="M">Male</option>
                          <option value="F">Female</option>
                        </select>
                        {genderError && (
                          <div className="fv-plugins-message-container">
                            <span
                              role="alert"
                              style={{ color: "#E25771", fontSize: "14px" }}
                            >
                              {genderError}
                            </span>
                          </div>
                        )}
                      </div>

                      <div className="text-center">
                        <button
                          type="button"
                          className="btn btn-lg w-50 mb-5 h-55px white-pink-btn"
                          style={{
                            boxShadow:
                              "0 10px 25px rgb(0 0 0 / 20%) !important",
                          }}
                          onClick={gotoSecondPage}
                        >
                          <span className="indicator-label">Continue</span>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
